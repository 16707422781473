import React from 'react'
import styled from 'styled-components'

import Layout from '../layouts'

const Button = styled.a`
  display: inline-block;
  padding: 1.25em 4em;
  border-bottom: none;
  border-radius: 3px;
  background: rgba(30, 30, 40, 0.5);
  color: white;
  font-family: 'aktiv-grotesk-std', 'helvetica neue', sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.1s ease-out;

  &:hover {
    background: rgba(30, 30, 40, 1);
  }
`;

const Desc = styled.p`
  width: 100%;
  font-family: 'aktiv-grotesk-std', 'helvetica neue', sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.2;
  line-height: 1.5;
  color: white;

  @media (min-width: 480px) {
    font-size: 18px;
  }

  @media (min-width: 760px) {
    font-size: 20px;
  }

  a {
    color: white;
    font-weight: 300;
    text-decoration: none;
    border-bottom: 1px dotted rgba(255,255,255,0.6);

    &:hover {
      border-bottom: 1px solid rgba(255,255,255,0.6);
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <Desc>I am a problem-solver who builds websites living in Phoenix, AZ. I have been creating digital experiences as Senior Web Designer &amp; Developer at <a href="http://fervorcreative.com" target="_blank" rel="noopener noreferrer">Fervor Creative</a> for clients in various industries.</Desc>
    <Desc>Currently available for freelance opportunties. If you'd like to chat, get in touch below.</Desc>
    <Button href="mailto:me@andrewsapiens.com">Let's Talk</Button>
  </Layout>
)

export default IndexPage
