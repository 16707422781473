import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  padding-bottom: 2rem;

  @media (min-width: 760px) {
    padding-bottom: 3rem;
  }

  img {
    width: 20px;
    height: 36px;
    margin: 0;
  }
`;

const Name = styled.h1`
  width: 100%;
  margin: 0 0 5px;
  font-family: 'aktiv-grotesk-std', 'helvetica neue', sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.2;
  line-height: 1.2;
  color: white;

  @media (min-width: 480px) {
    font-size: 28px;
  }

  @media (min-width: 760px) {
    font-size: 30px;
  }
`;

const Title = styled.p`
  width: 100%;
  font-family: 'aktiv-grotesk-std', 'helvetica neue', sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.2;
  line-height: 1.2;
  color: white;
  opacity: 0.8;

  @media (min-width: 480px) {
    font-size: 30px;
  }

  @media (min-width: 760px) {
    font-size: 30px;
  }
`;

const Header = ({ siteTitle }) => (
  <Container>
    <Name>Andrew Sapiens</Name>
    <Title>UI/UX Designer &amp; Developer</Title>
  </Container>
)

export default Header
